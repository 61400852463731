<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
                <b-overlay :show="loadingState">
                    <b-row>
                    <b-col lg="10" sm="12">
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                            <ValidationProvider name="Review Remarks (En)" vid="note" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="note"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('research_manage.review_remark_en')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                id="note"
                                v-model="recject.note"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Review Remarks (Bn)" vid="note_bn" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="note_bn"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('research_manage.review_remark_bn')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                id="note_bn"
                                v-model="recject.note_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                &nbsp;
                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                            </div>
                            </div>
                        </b-form>
                        </ValidationObserver>
                    </b-col>
                    </b-row>
                </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '../../../../../config/api_config'
import { proposalApprovalNoteUpdate } from '../../api/routes'
import researchTestingModal from '@/mixins/research-testing-modal'
export default {
  name: 'FormLayout',
  props: ['id'],
  mixins: [researchTestingModal],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      defaultValue: '',
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      recject: {
        note: '',
        note_bn: '',
        proposal_status: 3
      },
      seedNameList: []
    }
  },
  computed: {
        loadingState () {
          if (this.listReload) {
            return true
          } else if (this.loading) {
              return true
          } else {
            return false
          }
        }
  },
  created () {
  },
  mounted () {
    core.index()
  },
  methods: {
    async saveData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

       if (this.id) {
            result = await RestApi.putData(agriResearchServiceBaseUrl, `${proposalApprovalNoteUpdate}/${this.id}`, this.recject)
        }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)
      if (result.success) {
        this.$store.dispatch('AgriResearch/mutateCommonObj', { hasDropdownLoaded: false })
          // const notification = result.notification
          // this.$socket.emit('send-notification', notification)
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    }
  }
}
</script>
